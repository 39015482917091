import React from 'react'

import { IChildren } from '@interfaces/global.type'

import styles from './Header.module.sass'

interface IProps {
  children: IChildren
  tag?: string
  color?: 'yellow' | 'blue' | 'blueYellow'
  style?: React.CSSProperties
}

const Header = ({ tag = 'h3', children, color = 'yellow', style }: IProps) => {
  return React.createElement(
    tag,
    { className: `${styles.header} ${styles[color]}`, style },
    children,
  )
}

export default Header
