import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export const convertToDate = (date: string) => {
  const newDate = new Date(date)

  const day =
    newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()
  const month =
    newDate.getMonth() + 1 < 10
      ? `0${newDate.getMonth() + 1}`
      : newDate.getMonth() + 1
  const hours =
    newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
  const minutes =
    newDate.getMinutes() < 10
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes()

  return `${day}.${month}.${newDate.getFullYear()} ${hours}:${minutes}`
}

export const getDateRangeIn24Hours = (date: Date | null) => {
  if (!date) {
    return { startDay: null, endDay: null }
  }

  // get date range in 24 hours from 00:00 to 23:59
  const startDay = date
  startDay.setHours(0, 0, 0, 0)

  const endDay = date
  endDay.setHours(23, 59, 59, 999)

  return { startDay, endDay }
}

//convert timestamp to date
export const convertTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp)

  return date
}

export const getNameOfTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return timezone
}

export const converDateToTimeZone = (
  date: number,
  zoneName: string,
  isTimezoneShown: boolean = false,
) => {
  const tempDate = new Date(date)
  console.log('date', date)
  // const tempCityName = getCityData(city, 'EN')

  // if (tempCityName) {
  //   const getCityTimezone = cityTimezones.lookupViaCity(tempCityName)

  //   const result = DateTime.fromJSDate(tempDate, {
  //     zone: getCityTimezone[0]?.timezone,
  //   })

  //   return `${result.day}.${result.month}.${result.year} ${result.hour}:${result.minute} (timezone: ${result.zoneName})`
  // }

  // return 'Error'

  dayjs.extend(utc)
  dayjs.extend(timezone)

  const result = dayjs(tempDate).tz(zoneName).format('DD.MM.YYYY HH:mm')

  return `${result}${isTimezoneShown ? `(timezone: ${zoneName})` : ''}`
}

export const convertCreatedAt = (date: string, withTime = false) => {
  const dateObj = new Date(date)
  const day =
    dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()
  const month =
    dateObj.getMonth() + 1 < 10
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  const year = dateObj.getFullYear()
  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()

  if (withTime) {
    return `${day}.${month}.${year} ${hours}:${minutes}`
  }

  return `${day}.${month}.${year}`
}

export const convertToDateWithoutTime = (date: string) => {
  const dateObj = new Date(date)
  const day =
    dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()
  const month =
    dateObj.getMonth() + 1 < 10
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  const year = dateObj.getFullYear()

  return `${day}.${month}.${year}`
}
