import { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { ReactSVG } from 'react-svg'

import { convertCreatedAt } from '@utils/converToDate'

import SmallHeader from '../SmallHeader/SmallHeader'

import styles from './NewsItem.module.sass'

interface IProps {
  id: string
  title: string
  description: string
  imgUrls: any[]
  createdAt: string //TODO: change to Date
  [key: string]: any
}

const NewsItem = ({
  id = null,
  title = null,
  description = null,
  createdAt = null,
  imgUrls = null,
  //TODO: delete this props
  href = null,
  imgUrl = null,
  date = null,
  alt = null,
}) => {
  const [image, setImage] = useState(null)

  const getImages = () => {
    const firstImageUrl = imgUrls[0]?.path
      ? `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/${imgUrls[0].path}`
      : null

    setImage(firstImageUrl)
  }

  useEffect(() => {
    getImages()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <Image
          src={image ? image : '/images/sliderNews/img1.png'}
          alt='news item'
          layout='fill'
          objectFit='cover'
        />
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.dateContainer}>
          <div className={styles.iconContainer}>
            <ReactSVG src='/icons/calendar-two.svg' />
          </div>
          <p>{convertCreatedAt(createdAt)}</p>
        </div>

        <div className={styles.headerContainer}>
          <SmallHeader>{title.toString().substring(0, 50)}...</SmallHeader>
        </div>

        <p className={styles.description}>
          {description.toString().substring(0, 150)}...
        </p>

        <Link href={`/news/${id}`}>
          <div className={styles.linkContainer}>
            <p>Читати повністю</p>
            <div className={styles.iconLinkContainer}>
              <ReactSVG src='/icons/arrowLink.svg' />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NewsItem
