import React from 'react'
import Script from 'next/script'
import { ToastContainer } from 'react-toastify'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Organization, WithContext } from 'schema-dts'
import 'react-loading-skeleton/dist/skeleton.css'

import {
  IChildren,
  IFooterTypes,
  INavigationTypes,
  ISidebarMock,
} from '@interfaces/global.type'

import Navigation from '@components/UI/Navigation/Navigation'
import Footer from '@components/UI/Footer/Footer'

interface IProps {
  children: IChildren
  accentColor?: 'yellow' | 'blue'
  navLinksMock?: INavigationTypes
  footerContentMock?: IFooterTypes
  logoColor?: 'yellow' | 'blue' | 'blueYellow'
  sidebarMock?: ISidebarMock
}

const jsonLd: WithContext<Organization> = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Perevozka',
  url: 'https://perevozka.global',
  logo: 'https://perevozka.global/icons/logo-blue-yellow-with-text.svg',
}

const PageWrapper = ({
  children,
  accentColor = 'blue',
  navLinksMock = '',
  footerContentMock = 'main',
  logoColor = 'blueYellow',
  sidebarMock = 'main',
}: IProps) => {
  return (
    <>
      <SkeletonTheme baseColor='#333333' highlightColor='#555555'>
        <Navigation
          accentColor={accentColor}
          navLinksMock={navLinksMock}
          logoColor={logoColor}
          sidebarMock={sidebarMock}
        />
        <ToastContainer theme='dark' />
        {children}
        <Footer contentMock={footerContentMock} />
        <Script
          id='organization-schema'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLd),
          }}
        />
      </SkeletonTheme>
    </>
  )
}

export default PageWrapper
